// SCSS SYNTAX
// %message-shared {
//     border: 1px solid #ccc;
//     padding: 10px;
//     color: #333;
//   }

//   .message {
//     @extend %message-shared;
//   }

%orageButton {
	// background: linear-gradient(
	// 	146.93deg,
	// 	#f3362f 12.54%,
	// 	#fde731 91.22%,
	// 	#f44d2f 180.39%
	// );
	background-color: $color6;
	box-shadow: 0px 0px 36px #e92f3a;
	border-radius: 50px;
}

%yellowButton {
	// background: linear-gradient(180deg, #fde731 0%, #ff8e95 100%);
	background: #fb976d;
	border: 3px solid #9af3ff;
	box-shadow: 0px 0px 17px #61ecff;
	border-radius: 40px;
}

%shadow {
	-webkit-box-shadow: 0px 30px 40px rgba(0, 0, 0, 0.2);
	-moz-box-shadow: 0px 30px 40px rgba(0, 0, 0, 0.2);
	box-shadow: 0px 30px 40px rgba(0, 0, 0, 0.2);
}

%shadow2 {
	-webkit-box-shadow: 0px -30px 70px rgba(0, 0, 0, 0.1);
	-moz-box-shadow: 0px -30px 70px rgba(0, 0, 0, 0.1);
	box-shadow: 0px -30px 70px rgba(0, 0, 0, 0.1);
}

%listMargin {
	list-style: none;
	margin-block-start: 0;
	margin-block-end: 0;
	margin-inline-start: 0px;
	margin-inline-end: 0px;
	padding-inline-start: 0px;
}

// Font size
%h1 {
	font-family: $headerTitle;
	font-size: 80px;
	font-weight: 700;
	line-height: 75px;
	max-width: 1050px;
	z-index: 1;
	@media (max-width: 1440px) {
		max-width: 616px;
		font-size: 50px;
	}
	@media (max-width: 768px) {
		font-size: 34px;
		text-align: center;
		line-height: 40px;
	}
}

%h2 {
	font-size: 64px;
	font-weight: 400;
	font-style: normal;
	font-family: $fontFamily;
	@media (max-width: 1440px) {
		font-size: 34px;
	}
}
