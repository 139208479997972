.header {
	margin: 0 auto;
	width: 100%;
	text-align: center;
	position: relative;
	background-color: $dark;
	padding-bottom: 30px;
	@include centerBox();

	.backRight {
		position: absolute;
		top: 0;
		right: 0;
		left: auto;
		z-index: 0;
		width: 100%;
		max-width: 350px;
		@media (max-width: 1440px) {
			width: 34%;
		}

		@media (max-width: 600px) {
			display: none;
		}
	}
	.backLeft {
		position: absolute;
		top: 150px;
		left: 0;
		right: auto;
		z-index: 0;
		@media (max-width: 1440px) {
			width: 25%;
		}
		@media (max-width: 600px) {
			display: none;
		}
	}

	.titleBox {
		@include centerBox();
		width: 100%;
		max-width: 967px;
		position: relative;
		@media (max-width: 1440px) {
			max-width: 900px;
		}

		h1 {
			@include centerBox();
			@extend %h1;
			@media (max-width: 1440px) {
				margin-block-start: 0em;
				margin-block-end: 0em;
				margin-inline-start: 0px;
				margin-inline-end: 0px;
			}

			img {
				display: inline-block;
				margin-left: 15px;
				@media (max-width: 1440px) {
					max-width: 500px;
					width: 100%;
				}
			}
		}
	}

	.buttonContainer {
		position: relative;
		width: 100%;
		margin-top: 32px;
		z-index: 555;
		@include centerBox();
		@media (max-width: 768px) {
			margin-top: 10px;
		}
	}
	p {
		max-width: 1000px;
		text-align: center;
		margin-top: 20px;
		font-size: 26px;
		font-weight: 400;
		z-index: 1;
		line-height: 30px;
		@media (max-width: 1440px) {
			font-size: 20px;
			max-width: 850px;
		}
		@media (max-width: 768px) {
			padding: 20px;
		}
	}
	.asFeatured {
		z-index: 1;
		margin-top: 200px;
		width: 100%;
		@media (max-width: 1440px) {
			margin-top: 60px;
		}
		img {
			max-width: 1100px;
			width: 100%;
			height: auto;
			@media (max-width: 1440px) {
				max-width: 900px;
			}
		}
	}
	.pacmanGroup {
		width: 100%;
		text-align: right;
		margin-top: 30px;
		@media (max-width: 1440px) {
			img {
				max-width: 800px;
			}
		}
		@media (max-width: 768px) {
			img {
				width: 60%;
			}
		}
	}
}
