.nfts101 {
	font-family: $fontFamily;
	font-style: normal;
	font-weight: 400;
	font-size: 26px;
	line-height: 50px;
	padding-bottom: 20px;
	padding-top: 50px;
	background: $dark;
	@media (max-width: 768px) {
		padding-top: 50px;
		padding-bottom: 50px;
	}

	h2 {
		max-width: 900px;
		width: 100%;
		text-align: center;
		margin: 0 auto 20px;
		font-family: $headerTitle;
	}
	.wrapper {
		width: 100%;
		max-width: 900px;
		margin: 0 auto;
	}

	.accordion-wrapper {
		& + * {
			margin-top: 0.5em;
		}
	}

	.accordion-item {
		overflow: hidden;
		transition: max-height 0.3s cubic-bezier(1, 0, 1, 0);
		height: auto;
		max-height: 9999px;
	}

	.accordion-item.collapsed {
		max-height: 0;
		transition: max-height 0.35s cubic-bezier(0, 1, 0, 1);
	}

	.accordion-title {
		font-weight: 600;
		cursor: pointer;
		color: rgb(255, 255, 255);
		padding: 5px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		border-bottom: 1px white solid;
		@media (max-width: 768px) {
			font-size: 20px;
			line-height: 30px;
		}
		&::after {
			content: ' + ';
			@include centerBox();
			padding: 0;
			width: 50px;
			height: 50px;
			border-radius: 50%;
			background: rgba(217, 217, 217, 0.3);
			font-size: 30px;
			font-weight: bold;
			// width: 0;
			// height: 0;
			// border-left: 12px solid transparent;
			// border-right: 12px solid transparent;
			// border-top: 12px solid currentColor;
		}

		&:hover,
		&.open {
			color: white;
		}

		&.open {
			&::after {
				content: '';
				border-top: 0;
				border-bottom: 12px solid;
			}
		}
	}

	.accordion-content {
		padding: 1em 1.5em;
		p {
			margin-bottom: 15px;
			line-height: 27px;
			@media (max-width: 768px) {
				font-size: 18px;
				text-align: left;
				line-height: 20px;
			}
			strong {
				color: black;
				width: 70px;
				display: inline-block;
				background-color: black;
			}
			a {
				text-decoration: underline;
			}
		}
	}
}
