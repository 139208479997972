*,
*::before,
*::after {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
}

/* Set core body defaults */
body {
	text-rendering: optimizeSpeed;
	font-family: $fontFamily !important;
	color: white;
	background-repeat: repeat;
	background-image: url('../assets/backg.jpg');
	@media (max-width: 768px) {
		background-image: none;
	}

	.App {
		background-color: $dark;
		width: 100%;
		.contentWrap {
			width: 100%;
			margin: 0 auto;
			@media (min-width: 1920px) {
				max-width: 1920px;
			}
			@media (max-width: 1440px) {
				max-width: 1440px;
			}
		}
	}
}
.specaialBackg {
	background-position: top center;
	background-position-y: -70px;
	overflow: hidden;
	@media (max-width: 768px) {
		background-size: 100%;
	}
}

html {
	scroll-behavior: smooth;
}

h2 {
	font-family: $headerTitle;
	font-size: 64px;
}

p {
	font-size: 21px;
	font-weight: 400;
	font-family: $fontFamily;
	@media (max-width: 768px) {
		font-size: 18px;
		text-align: center;
	}
}

a {
	cursor: pointer;
	text-decoration: none;
	color: white;
}

.appContainer {
	width: 100%;
	position: relative;
	margin: 0 auto;
}

.popUpFullContainer {
	width: 100%;
	height: auto;
	background-color: rgba($dark, 0.9);
	@include centerBox();
	background-size: cover;
	background-attachment: fixed;
	position: fixed;
	z-index: 9999999999;
	inset: 0;
}

.hiddePopUp {
	display: none !important;
	opacity: 0;
}

// Loader
.loader-container {
	width: 100%;
	height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;
	position: fixed;
	background: rgba($mainColor, 1);
	z-index: 1;
}

.spinner {
	width: 64px;
	height: 64px;
	border: 8px solid;
	border-color: #3d5af1 transparent #3d5af1 transparent;
	border-radius: 50%;
	animation: spin-anim 1.2s linear infinite;
}

@keyframes spin-anim {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}
