@import url('https://fonts.googleapis.com/css2?family=Audiowide&family=Red+Hat+Display:wght@300;400;500;600;700;800;900&display=swap');

$fontFamily: 'Red Hat Display', sans-serif;
$titleFont: 'Red Hat Display', sans-serif;
$headerTitle: 'Audiowide', cursive;

//  Colors
$color1: #8f5cbe;
$color2: #6c3696;
$color3: #e767a7;
$color4: #fde731;
$color5: #45a6b3;

// Button color
$color6: #f14d45;

// Contrast
$dark: #000000;
$light: #ffffff;
$mainColor: #300055;

$fontColor: white;
