.popUpBox {
	width: 100%;
	max-width: 1200px;
	background: linear-gradient(180deg, #45a6b3 0%, #1294a5 100%);
	@include centerBox(space-between, center);
	position: relative;
	overflow: hidden;
	border-radius: 50px;
	@media (max-width: 768px) {
		margin: 5px;
		border-radius: 12px;
	}
	@media (max-width: 390px) {
		border-radius: 12px;
		margin: 10px;
	}

	.closeBtn {
		position: absolute;
		top: 20px;
		right: 20px;
		cursor: pointer;
		@media (max-width: 390px) {
			width: 30px;
		}
	}
	.contentBox {
		padding: 18px 50px;
		width: 50%;
		@media (max-width: 768px) {
			width: 100%;
		}
		h3 {
			font-family: $fontFamily;
			font-style: normal;
			font-weight: 400;
			font-size: 50px;
			line-height: 71px;
			color: $dark;
			@media (max-width: 768px) {
				text-align: center;
				font-size: 25px;
				line-height: 26px;
				margin-top: 10px;
			}
			@media (max-width: 390px) {
				font-size: 25px;
				line-height: 26px;
				margin-top: 10px;
			}
		}
		h2 {
			font-family: $headerTitle;
			font-style: normal;
			font-weight: 400;
			font-size: 78px;
			line-height: 100px;
			color: $dark;
			@media (max-width: 768px) {
				text-align: center;
				font-size: 35px;
				line-height: 70px;
			}
			@media (max-width: 390px) {
				font-size: 30px;
				line-height: 35px;
			}
		}
		p {
			font-family: $fontFamily;
			font-style: normal;
			font-weight: 400;
			font-size: 26px;
			line-height: 28px;
			color: $dark;
			margin-bottom: 20px;
			@media (max-width: 768px) {
				text-align: center;
				font-size: 22px;
				line-height: 26px;
			}
			@media (max-width: 390px) {
				// font-size: 22px;
				line-height: 22px;
				margin-top: 20px;
			}
		}
	}
	.subsImage {
		width: 50%;
		max-width: 500px;
		margin: 0;
		padding: 0;
		img {
			width: 100%;
		}
		@media (max-width: 768px) {
			width: 100%;
			margin: 20px auto;
			max-width: 300px;
			img {
				overflow: hidden;
				border-radius: 42px;
			}
		}
		@media (max-width: 390px) {
			max-width: 200px;
		}
	}
	.mailchimpS {
		margin-top: 20px;
		border: 2px solid $dark;
		background-color: $light;
		padding: 2px;
		border-radius: 30px;
		@media (max-width: 768px) {
			border: none;
			background-color: transparent;
		}
		.inputBox {
			max-width: 100%;

			input {
				width: 68%;
				padding-left: 20px;
				font-size: 24px;
				color: $dark;
				@media (max-width: 768px) {
					width: 100%;
					background-color: $light;
					border-radius: 12px;
					border: 1px solid $dark;
					text-align: center;
				}
			}
		}
		.submButton {
			width: 28%;
			padding: 7px;

			@media (max-width: 768px) {
				width: 100%;
				padding: 5px;
				margin: 10px auto 0;
			}
		}
	}
}
