.lcs-section {
	position: relative;
	width: 100%;
	padding-top: 20px;
	padding-bottom: 100px;
	background: $mainColor;
	// background: linear-gradient(
	// 	179.1deg,
	// 	#3b0367 0%,
	// 	#372185 31.8%,
	// 	#124da0 75.48%,
	// 	#3b0367 100%
	// );
	box-shadow: 0px 0px 200px rgba(255, 255, 255, 0.25);
	@include centerBox();
	@media (max-width: 768px) {
		padding-bottom: 20px;
	}
	.imgBack {
		position: absolute;
		top: -250px;
		left: 0;
		@media (max-width: 1440px) {
			width: 100%;
			top: -150px;
		}
		@media (max-width: 768px) {
			top: 10px;
		}
	}
	h2 {
		margin: 0 auto 100px;
		text-align: center;
		width: 100%;
		max-width: 1220px;
		@extend %h2;
		@media (max-width: 1440px) {
			max-width: 700px;
		}
		@media (max-width: 768px) {
			font-size: 24px;
			max-width: 350px;
			margin: 0 auto 20px;
		}

		strong {
			font-weight: 400;
			font-size: 80px;
			font-family: $headerTitle;
			@media (max-width: 1440px) {
				font-size: 60px;
			}
			@media (max-width: 768px) {
				font-size: 24px;
			}
		}
	}
	.boxContainer {
		width: 100%;
		max-width: 1479px;
		@include centerBox(space-between, center);
		@media (max-width: 1440px) {
			max-width: 1070px;
		}
		@media (max-width: 768px) {
			margin: 0 auto;
			max-width: 360px;
			@include centerBox();
		}
		.learn,
		.connect,
		.score {
			width: 100%;
			max-width: 440px;
			min-height: 360px;
			border-radius: 20px;
			margin: 0 auto;
			position: relative;
			@include centerBox();
			@media (max-width: 1440px) {
				max-width: 350px;
				min-height: 340px;
			}
			@media (max-width: 768px) {
				margin-bottom: 20px;
			}
			h3 {
				font-size: 48px;
				text-align: center;
				font-family: $headerTitle;
				font-weight: 400;
				padding: 30px 0;
				@media (max-width: 1440px) {
					padding: 15px 0;
					font-size: 40px;
				}
			}
			p {
				font-weight: 400;
				font-size: 26px;
				line-height: 50px;
				text-align: center;
				@media (max-width: 1440px) {
					font-size: 24px;
					line-height: 30px;
					padding: 0 5px;
				}
			}
			.callToAction {
				margin: 35px auto;
				border-radius: 40px;
				padding: 10px 23px;
				// padding: 20px 50px;
				font-size: 26px;
				max-width: 268px;
			}
		}
		.learn {
			@media (max-width: 768px) {
				margin-top: 20px;
			}
			img {
				position: absolute;
				top: 35px;
				right: 15px;
				left: auto;
			}
			background: linear-gradient(135.12deg, #2f79b5 0%, #0a7bab 100%);
			.callToAction {
				background: darken(#0a7bab, 10);
				filter: drop-shadow(0px 0px 36px #1590a0);
				&:hover {
					filter: drop-shadow(10px 10px 20px rgba(12, 96, 107, 0.2));
				}
			}
		}
		.connect {
			margin: 0 50px;
			background: linear-gradient(180deg, #d14e6e -10.83%, #e44a73 93.44%);
			@media (max-width: 1440px) {
				margin: 0 3px;
			}
			@media (max-width: 768px) {
				margin-bottom: 20px;
			}
			img {
				position: absolute;
				top: 35px;
				right: 15px;
				left: auto;
			}

			.callToAction {
				background: darken(#d34067, 10);
				filter: drop-shadow(10px 10px 20px rgba(0, 0, 0, 0.1));
				&:hover {
					filter: drop-shadow(10px 10px 20px rgba(0, 0, 0, 0.3));
				}
			}
		}
		.score {
			img {
				position: absolute;
				top: 35px;
				right: 15px;
				left: auto;
			}
			background: linear-gradient(135.12deg, #6d77e9 0%, #3745f5 100%);
			.callToAction {
				background: darken(#4e58ca, 10);
				filter: drop-shadow(10px 10px 20px rgba(0, 0, 0, 0.1));
				&:hover {
					filter: drop-shadow(10px 10px 20px rgba(0, 0, 0, 0.3));
				}
			}
		}
	}
}
