.andRemember-section {
	padding-top: 20px;
	background-color: $mainColor;
	@include centerBox();
	h2 {
		font-family: $headerTitle;
		font-style: normal;
		font-weight: 400;
		font-size: 80px;
		line-height: 85px;
		width: 100%;
		text-align: center;
		// margin-bottom: 29px;
		@media (max-width: 1440px) {
			font-size: 60px;
		}
		@media (max-width: 768px) {
			font-size: 40px;
			line-height: 60px;
			margin-bottom: 10px;
		}
	}
	p {
		font-family: $fontFamily;
		font-style: normal;
		font-weight: 400;
		font-size: 35px;
		line-height: 100px;
		width: 100%;
		text-align: center;
		@media (max-width: 1440px) {
			line-height: 50px;
		}
		@media (max-width: 768px) {
			font-size: 25px;
			line-height: 30px;
		}
	}
	.shortPharagraph {
		width: 100%;
		max-width: 950px;
		text-align: center;
	}
	img {
		margin: 10px auto;
		width: 100%;
		@media (max-width: 768px) {
			margin: 0 auto;
		}
	}
	.buttonContainer {
		@include centerBox();
		width: 100%;
		margin: 20px auto;
		z-index: 1;
		@media (max-width: 768px) {
			margin: 20px auto;
		}

		a {
			font-family: $headerTitle;
			font-style: normal;
			font-weight: 400;
			font-size: 48px;
			line-height: 61px;
			@media (max-width: 1440px) {
				max-width: 211px;
				height: 41px;
				font-size: 24px;
				padding: 5px 3px;
			}
			@media (max-width: 768px) {
				max-width: 154px;
				height: 41px;
				padding: 5px 3px;
				font-size: 18px;
			}
		}
	}
}
